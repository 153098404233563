import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 43 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5.75 24.917h1.917v13.416a3.838 3.838 0 0 0 3.833 3.834h23a3.838 3.838 0 0 0 3.833-3.834V24.917h1.917a1.916 1.916 0 0 0 1.88-2.291 1.916 1.916 0 0 0-.525-.981l-17.25-17.25a1.915 1.915 0 0 0-2.71 0l-17.25 17.25a1.915 1.915 0 0 0 1.355 3.272ZM23 8.46l11.5 11.5v8.79l.002 9.583H11.5V19.96L23 8.46Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M23 34.5c7.097 0 9.394-6.783 9.487-7.071l-3.641-1.19c-.015.044-1.497 4.428-5.846 4.428-4.289 0-5.788-4.257-5.848-4.439l-3.64 1.201c.094.288 2.391 7.071 9.488 7.071Z"
    }, null, -1)
  ])))
}
export default { render: render }